// History of used identifiers: ['old_viewer']
// Might be helpful in the future because already used identifiers are stored in the user settings and still exist.
// So we should not re-use them for different features.

export const legacyFeatures = [] as const;

// These are old, unused identifiers. This array is added for sake of typing.
export const oldLegacyFeatures = ['old_viewer'] as const;

export const legacyFeaturesTranslations = {
  old_viewer: $localize `:@@legacy_features.viewer:Old Viewer`,
} as const;
