// History of used identifiers: ['lists', 'viewer', 'table_input', 'new_line_on_enter', 'highlight', 'extraction_rules_editor']
// Might be helpful in the future because already used identifiers are stored in the user settings and still exist.
// So we should not re-use them for different features.

export const betaFeatures = [
  'table_input',
] as const;

// These are old, unused identifiers. This array is added for sake of typing.
export const oldBetaFeatures = [
  'lists',
  'viewer',
  'new_line_on_enter',
  'extraction_rules_editor',
  'highlight'
] as const;

// We want to introduce beta features without showing the popup
// Those features should be listed here with a default value to be saved in the user settings
export const betaFeaturesDefaultValues = {
  table_input: true,
} as const;

export const betaFeaturesTranslations = {
  table_input: $localize `:@@beta_features.table_input:Table Input`
} as const;
